import Logo from "../../../images/logo.svg";

export default function ApplicationLogo({ className }: { className?: string }) {
  return (
    <img
      src={Logo}
      className={`application-logo ${className ? className : ""}`}
      alt="everflex_logo"
    />
  );
}
